import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketMessagesList = ({ dispatch, scrollToBottom }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleAppMessage = (data) => {
        if (
          (data.action === "createAppMessageTicket")
          && (data.message.ticketId.toString() === window.selectedTicketId)
          && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });
          scrollToBottom(true);
        }
  
        if (
          (data.action === "updateAppMessage")
          && (data.ticketId.toString() === window.selectedTicketId)
          && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
        }
  
        if (
          (data.action === "transcribeAppMessage")
          && (data.ticketId.toString() === window.selectedTicketId)
          && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
        }
      };
  
      socket.on("appMessage", handleAppMessage);
  
      return () => {
        socket.off("appMessage", handleAppMessage);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, scrollToBottom]);

  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketMessagesList;